<template>
  <ol class="dp-pro-recipe-step-list">
    <DpProRecipeStepListItem
      v-for="(content, index) in contents"
      :key="index"
      :content="content" />
  </ol>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpProRecipeStepListItem: () =>
      import('@/components/dripPod/proRecipe/StepListItem.vue')
  },

  props: {
    contents: {
      type: Array,
      default: null
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-step-list {
  counter-reset: step-list;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4;

  > li {
    counter-increment: step-list;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px 12px;
    grid-auto-columns: auto 1fr;

    &::before {
      display: grid;
      content: counter(step-list);
      place-items: center;
      flex-shrink: 0;
      margin-top: 0.24em;
      margin-left: 2px;
      background-color: variables.$dpBlack01;
      width: 16px;
      height: 16px;
      border-radius: 1em;
      color: variables.$dpWhite01;
      font-size: 10px;
      font-family: Oswald;
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
